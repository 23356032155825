/**
 * App Main
 * Author: Fernando Karnagi <fernando@cyder.com.sg>
 */
import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ResetPassword = React.lazy(() => import('./views/pages/resetpassword/ResetPassword'));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch> 
          <Route path="/resetpassword/:token" name="Reset Password" render={props => <ResetPassword {...props} />} />
            <Route
              path="/"
              name="MyCorp"
              render={(props) =>
                localStorage.getItem("mycorp-project-user") ? (
                  <TheLayout {...props} />
                ) : (
                    <Login {...props} />
                  )
              }
            />
           
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
